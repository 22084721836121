<template>
    <v-layout align-center class="wrapper">
        <v-checkbox :ripple="false" v-model="internalValue"></v-checkbox>
        <v-layout shrink column @click.prevent="internalValue=!internalValue">
            <DefaultLabel>
                <span v-html="messages.title"></span>
            </DefaultLabel>
            <DefaultSubText color="lightgray"><span v-html="messages.isRequired"></span></DefaultSubText>
        </v-layout>
        <v-layout grow class="spacer" @click.prevent="onClick"></v-layout>
        <div @click.prevent="onClick" class="enter-icon"></div>
        <BooksyRulesPopup v-if="showRules" v-model="showRules" :rulesDescription="messages"/>
    </v-layout>
</template>

<script>
export default {
    props: {
        value: {},
        showRules: false,
        messages: {
            type: Object,
            default() {
                return {
                    title: "",
                    description: "",
                    isRequired: ""
                }
            }
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    components: {
        DefaultLabel: () => import("@/components/text/DefaultLabel"),
        DefaultSubText: () => import("@/components/text/DefaultSubText"),
        BooksyRulesPopup: () => import("@/components/popups/BooksyRulesPopup"),
    },
    methods: {
        onClick() {
            this.showRules=true
        }
    }
}
</script>

<style lang="scss" scoped>
.enter-icon {
    font-family: "b-icons";
    font-size: 18px;
    height: 50px;
    vertical-align: middle;
    line-height: 50px;
}

.wrapper {
    cursor: pointer;
}

.spacer {
    height: 50px;
}
</style>